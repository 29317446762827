import React from "react";
import styles from "./ProjectSection.module.css";
import ProjectCard from "./ProjectCard";
import SectionTitle from "./SectionTitle";

import project_image1 from "../../utils/projects/project_image1.png"
import project_image2 from "../../utils/projects/project_image2.png"
import project_image3 from "../../utils/projects/project_image3.png"
import project_image4 from "../../utils/projects/project_image4.png"
import project_image5 from "../../utils/projects/project_image5.png"
import project_image6 from "../../utils/projects/project_image6.png"

const ventureProjects = [
  {
    image:
      project_image1,
    title: "ReThinkPack",
    description:
      "We are a young startup driven by a passion to combat packaging pollution by revolutionary shift packaging practices through holistic sustainable & circular economy design and redesign waste out from the system. Our mission is to analyze and provide solutions for any challenges that may arise in the packaging design process",
    readMore: 'https://rethinkpack.com/',
  },
  {
    image:
      project_image2,
    title: "SwimPreceptors",
    description:
      "Swim Preceptors is a group of Gen Zers committed to innovative teaching methods, aiming to bring out the full potential of each preceptee, introducing a fresher syllabus on swimming, the #SwimPreceptorsWay.",
    readMore: 'https://www.swimpreceptors.com/',
  },
];

const softwareProjects = [
  {
    image:
      project_image3,
    title: "Computer Vision, Training AI model",
    description:
      "Computer vision was used to solve the CAPTCHAs and train the AI model to recognize these CAPTCHA photos. Simultaneously, we ensured the bot mimicked human behavior to minimize the chances of detection.",
    readMore: '',
  },
  {
    image:
      project_image4,
    title: "Customized CRM",
    description:
      "A robust CRM system designed to streamline sales operations. By automating repetitive tasks and optimizing sales workflows, it empowers teams to focus on strategic initiatives and build stronger customer relationships.",
    readMore: '',
  },
  {
    image:
      project_image5,
    title: "Whatsapp Customer Support",
    description:
      "A user-friendly customer support panel with AI-powered chat functionality. By integrating WhatsApp, we provide an accessible platform for customers to receive initial assistance from an AI chatbot before being seamlessly transferred to a human agent when necessary.",
    readMore: '',
  },
];

const securityProjects = [
  {
    image:
      project_image6,
    title: "Centralized Security",
    description:
      "Recognizing the critical role of cyber security in early-stage startups, we offer a centralized, robust security infrastructure. Unlike traditional models that prioritize development over protection, we empower businesses to focus on growth by providing a fortified digital environment which is free. Our comprehensive approach, encompassing both software and physical security measures, these efforts are imposed to encourage company independence.",
    readMore: '',
  },
];

function ProjectSection() {
  return (
    <>
      <section className={styles.projectSection}>
        <SectionTitle>
          Our <strong>Projects</strong>
        </SectionTitle>

        <div className={styles.categorySection}>
          <h2 className={styles.categoryTitle}>Venture Building</h2>
          {ventureProjects.map((project, index) => (
            <ProjectCard key={index} {...project} />
          ))}
        </div>

        <div className={styles.categorySection}>
          <h2 className={styles.categoryTitle}>Software Development</h2>
          {softwareProjects.map((project, index) => (
            <ProjectCard key={index} {...project} />
          ))}
        </div>

        <div className={styles.categorySection}>
          <h2 className={styles.categoryTitle}>Security Implementation</h2>
          {securityProjects.map((project, index) => (
            <ProjectCard key={index} {...project} />
          ))}
        </div>
      </section>
      <blockquote className={styles.testimonial}>
        A vast network of skills encompasses a diverse and extensive range of
        competencies, abilities, and expertise. It's akin to a sprawling
        ecosystem where individuals possess specialized knowledge that, when
        combined, creates a powerful and versatile resource.
      </blockquote>
    </>
  );
}

export default ProjectSection;
