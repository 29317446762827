import React from "react";
import styles from "./CommunitySection.module.css";

function CommunitySection() {
  return (
    <section className={styles.communitySection}>
      <div className={styles.leftSide}>
        <h1>Join Dusky Team</h1>
      </div>
      <div className={styles.rightSide}>
        <div className={styles.communityContent}>        
          <p className={styles.communityDescription}>
            Join our community of innovators, problem solvers and owners who apply
            scientific discovery techniques to make crypto markets a better place
            for everyone.
          </p>
          <p className={styles.teamDescription}>
            As we emphasize it in our name – Dusky Team, we are a team. A team of
            bright, talented people, each masters of their specialty, curious
            about the world and eager to solve the new exciting cryptocurrency
            market problems, build cool stuff and have fun whilst doing so!
          </p>
          <a href="#" className={styles.learnMoreLink}>
            Learn more about working with us
          </a>
        </div>
      </div>
    </section>
  );
}

export default CommunitySection;
