import React from "react";
import Hero from "./Hero";
import CustomPartners from "./CustomPartners";
import Memberships from "./Memberships";
import WorkingProcess from "./WorkingProcess";
import WhyUsCard from "./WhyUsCard";
import styles from "./LandingPage.module.css";

import service_image1 from '../../utils/Assossiates/service_images/service_image1.png';
import service_image2 from '../../utils/Assossiates/service_images/service_image2.png';
import service_image3 from '../../utils/Assossiates/service_images/service_image3.png';
import service_image4 from '../../utils/Assossiates/service_images/service_image4.png';
import service_image5 from '../../utils/Assossiates/service_images/service_image5.png';
import service_image6 from '../../utils/Assossiates/service_images/service_image6.png';

const services = [
  { title: "Risk Mitigation", image: service_image1, alt: "Risk Mitigation Image" },
  { title: "Reduced Risk For Investors", image: service_image2, alt: "Reduced Risk For Investors Image" },
  { title: "Enhanced Collaboration", image: service_image3, alt: "Enhanced Collaboration Image" },
  { title: "Market Intelligence", image: service_image4, alt: "Market Intelligence Image" },
  { title: "High-Quality Deal Flow", image: service_image5, alt: "High-Quality Deal Flow Image" },
  { title: "Analytics and Tracking", image: service_image6, alt: "Analytics and Tracking Image" },
];

const LandingPage = () => (
  <div className={styles.landingPage}>
    <main>
      <Hero />
      <CustomPartners/>
      <Memberships />
      <section className={styles.whyUsSection}>
        <h2 className={styles.whyUsTitle}>Why Us</h2>
        <div className={styles.cardGroup}>
          {services.map((service, index) => (
            <WhyUsCard
              key={index}
              title={service.title}
              image={service.image}
              alt={service.alt}
            />
          ))}
        </div>
      </section>
      <WorkingProcess />
    </main>
  </div>
);

export default LandingPage;
