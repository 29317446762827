import React from "react";
import styles from "./ContactPage.module.css";
import ContactForm from "./ContactForm";
import ContactImage from "./ContactImage";
import CommunitySection from "./CommunitySection";

function ContactPage() {
  return (
    <main className={styles.contactPage}>
      <section className={styles.contactSection}>
        <div className={styles.contactContent}>
          <h1 className={styles.contactTitle}>Contact Us</h1>
          <p className={styles.contactDescription}>
            We're here to help! Send us your query via the form below or send us
            an email at{" "}
            <span className={styles.emailHighlight}>duskytechco@gmail.com</span>{" "}
            for any issue you're facing
          </p>
          <ContactForm />
        </div>
        <ContactImage />
      </section>
      <CommunitySection />
    </main>
  );
}

export default ContactPage;
