/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styles from "./VentureBuilding.module.css";
import ValueCard from "./ValueCard";

const values = [
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/ae6907d729c003b89b859111dc6b016ac8f141fa094678624888295155f8969c?apiKey=19cd871fe5a5421a8a01e183ca4b7671&&apiKey=19cd871fe5a5421a8a01e183ca4b7671",
    title: "Transparency",
    description: "In our Venture Building we offer everything at cost.",
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/7031258d8c9506502b424241ff7dd126f49e3e5f27d157d98644b6aea1beeb5a?apiKey=19cd871fe5a5421a8a01e183ca4b7671&&apiKey=19cd871fe5a5421a8a01e183ca4b7671",
    title: "Culture",
    description:
      "We work with people that are passionate in seeing your success.",
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/513dd308554045bb3776efdd16ccb7c950f6d7f5f7328842bf1e99cdd3f4fa80?apiKey=19cd871fe5a5421a8a01e183ca4b7671&&apiKey=19cd871fe5a5421a8a01e183ca4b7671",
    title: "Flexibility",
    description: "To help startups save more money we offer elastic expansion",
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/714f94ab24593fe4ca18b557e80a2b1c8733104ebe85a6ae3f3fac2243ad626a?apiKey=19cd871fe5a5421a8a01e183ca4b7671&&apiKey=19cd871fe5a5421a8a01e183ca4b7671",
    title: "Commitment",
    description: "We ensure to only commit long term all to see you succeed",
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/0ed14bec2ff6cedd0847b33001c30214b194301424122adb04d3162fa782f119?apiKey=19cd871fe5a5421a8a01e183ca4b7671&&apiKey=19cd871fe5a5421a8a01e183ca4b7671",
    title: "Team Work",
    description:
      "We will make sure that startups do not suffer with being alone",
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/703888b1d3a9844b2b28de52271b9cb391c3d946531cb0f7e48f6006b66ae4b9?apiKey=19cd871fe5a5421a8a01e183ca4b7671&&apiKey=19cd871fe5a5421a8a01e183ca4b7671",
    title: "Innovative",
    description: "We are committed to bring innovations to the world",
  },
];

function ValuesSection() {
  return (
    <section className={styles.valuesSection}>
      <h2 className={styles.sectionTitle}>Our Values</h2>
      <div className={styles.valueCards}>
        {values.map((value, index) => (
          <ValueCard key={index} {...value} />
        ))}
      </div>
    </section>
  );
}

export default ValuesSection;
