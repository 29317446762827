import React from 'react';
import { useRoutes } from 'react-router-dom';
import routes from './utils/routing';
import Navbar from './utils/common/Navbar';
import Footer from './utils/common/Footer';

function App() {
  const routing = useRoutes(routes);
  return (
    <>
      <Navbar/>
      {routing}
      <Footer/>
    </>
  );
}

export default App;
