import React from "react";
import Slider from "react-slick";
import styles from "./VentureBuilding.module.css";
import ServiceCard from "./ServiceCard";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const services = [
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/c75a26332c69b9156666be0a2b4554ff1d0d3feb21bff016087f5fab851b5e0e?apiKey=19cd871fe5a5421a8a01e183ca4b7671&&apiKey=19cd871fe5a5421a8a01e183ca4b7671",
    title: "Marketing and Designing",
    description:
      "A Website is an extension of yourself and we can help you to express it properly. Your website is your number one marketing asset because we live in a digital age.",
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/bcdba85a43fbb3089c443633a87098ea66ccf829a966d3c4c4645998b9a7c5d4?apiKey=19cd871fe5a5421a8a01e183ca4b7671&&apiKey=19cd871fe5a5421a8a01e183ca4b7671",
    title: "Software Development",
    description:
      "Dusky leverages its extensive network to cultivate strategic partnerships across various domains, encompassing investors, marketing specialists, and operational support personnel. This collaborative approach fosters a cost-effective venture building ecosystem.",
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/1bca776cb327332e1908e3bb4cb4e0b2ad5188baf21da2ed80557403c52ceea5?apiKey=19cd871fe5a5421a8a01e183ca4b7671&&apiKey=19cd871fe5a5421a8a01e183ca4b7671",
    title: "Human Resources",
    description:
      "A Website is an extension of yourself and we can help you to express it properly. Your website is your number one marketing asset because we live in a digital age.",
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/bcdba85a43fbb3089c443633a87098ea66ccf829a966d3c4c4645998b9a7c5d4?apiKey=19cd871fe5a5421a8a01e183ca4b7671&&apiKey=19cd871fe5a5421a8a01e183ca4b7671",
    title: "Funding",
    description:
      "Securing funding is essential for accelerating business growth. Strategic allocation of these funds is crucial to maximize return on investment and achieve key business objectives.",
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/bcdba85a43fbb3089c443633a87098ea66ccf829a966d3c4c4645998b9a7c5d4?apiKey=19cd871fe5a5421a8a01e183ca4b7671&&apiKey=19cd871fe5a5421a8a01e183ca4b7671",
    title: "Market Research",
    description:
      "Market research systematically gathers and analyzes data about a target market and its customers. Its goal is to understand consumer needs, preferences, and behaviors to inform business decisions.",
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/bcdba85a43fbb3089c443633a87098ea66ccf829a966d3c4c4645998b9a7c5d4?apiKey=19cd871fe5a5421a8a01e183ca4b7671&&apiKey=19cd871fe5a5421a8a01e183ca4b7671",
    title: "Business Strategy",
    description:
      "A well-structured business plan is a roadmap, outlining goals, strategies, and resource allocation to minimize risks and optimize business performance.",
  }
];


const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      className={styles.nextArrow}
      onClick={onClick}
    />
  );
};

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      className={styles.prevArrow}
      onClick={onClick}
    />
  );
};


function ServicesSection() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  

  return (
    <section className={styles.servicesSection}>
      <h2 className={styles.sectionTitle}>Services we offer</h2>
      <Slider {...settings} className={styles.cardsContainer}>
        {services.map((service, index) => (
          <ServiceCard key={index} {...service} />
        ))}
      </Slider>
    </section>
  );
}

export default ServicesSection;