  import React from "react";
  import styles from "./VentureBuilding.module.css";

  function ServiceCard({ icon, title, description }) {
    return (
      <div className={styles.serviceCard}>
        <div className={styles.serviceIconWrapper}>
          <img src={icon} alt="" className={styles.serviceIcon} />
        </div>
        <h3 className={styles.serviceTitle}>{title}</h3>
        <p className={styles.serviceDescription}>{description}</p>
      </div>
    );
  }

  export default ServiceCard;
