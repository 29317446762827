import React from "react";
import styles from "./ProjectCard.module.css";

function ProjectCard({ image, title, description, readMore }) {
  return (
    <article className={styles.projectCard}>
      <div className={styles.imageWrapper}>
        <img src={image} alt={title} className={styles.projectImage} />
      </div>
      <div className={styles.contentWrapper}>
        <h3 className={styles.projectTitle}>{title}</h3>
        <p className={styles.projectDescription}>{description}</p>
        {readMore && (
          <a
            href={readMore}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.readMoreButton}
          >
            Read more
            <span className={styles.arrowIcon} aria-hidden="true">
              →
            </span>
          </a>
        )}      
      </div>
    </article>
  );
}

export default ProjectCard;
