import React from "react";
import styles from "./LandingPage.module.css";

const WhyUsCard = ({ title, image, alt }) => (
  <div className={styles.card}>
    <div className={styles.cardContent}>
      <div className={styles.cardTitle}>{title}</div>
      {/* <a href="#" className={styles.cardLink}>
        <span className={styles.cardLinkArrow}>&#8599;</span> Learn More
      </a> */}
    </div>
    <div className={styles.cardImage}>
      <img src={image} alt={alt} />
    </div>
  </div>
);

export default WhyUsCard;
