import React from "react";
import { Link } from "react-router-dom";
import styles from "./VentureBuilding.module.css";
import Partners from "../Associates/Partners";

function AboutUsSection() {
  return (
    <>
      <section className={styles.aboutUsSection}>
        <div className={styles.aboutUsContent}>
          <h2 className={styles.aboutUsTitle}>Who are we?</h2>
          <p className={styles.aboutUsDescription}>
            We've harnessed the experience and expertise we gained from our
            successful software agency to launch a new venture building studio.
            This exciting shift allows us to empower promising startups even
            further.
            <br />
            <br />
            We understand the challenges of launching a tech venture, and that's
            why we focus on minimizing risk and maximizing efficiency. Our
            structured approach provides startups with the resources they need to
            thrive, including shared infrastructure, marketing tools, and access
            to our network of investors and partners.
            <br />
            <br />
            We believe in the power of collaboration and share a genuine passion
            for the success of each startup we work with. By combining our
            expertise with their innovative ideas, we create a launchpad for
            success, allowing ventures to spin-off their tech at their own pace
            and with the right amount of financial investment.
          </p>
          <Link to="/About_Us" className={styles.moreInfoButton}>See more</Link>
        </div>
        <div className={styles.aboutUsImageWrapper}>
          <img
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/09039b03f34cee211f484dedd69e645ff25c5079a956633319df35e6e22f4cbd?apiKey=19cd871fe5a5421a8a01e183ca4b7671&&apiKey=19cd871fe5a5421a8a01e183ca4b7671"
            alt="About Us illustration"
            className={styles.aboutUsImage}
          />
        </div>
      </section>
      <Partners />
    </>
  );
}

export default AboutUsSection;
