import React, { useState } from "react";
import ProcessStep from "./ProcessStep";
import styles from "./VentureBuilding.module.css";

const processSteps = [
  {
    number: "01",
    title: "Consultation",
    description:
      "During the initial consultation, we will discuss your business goals and objectives, target audience, and current marketing efforts. This will allow us to understand your needs and tailor our services to best fit your requirements.",
  },
  {
    number: "02",
    title: "Research and Strategy Development",
    description:
      "A robust business model is the cornerstone for successful R&D. It provides a clear framework for directing innovation efforts and ensuring that new products or services align with the company's overall strategic objectives.",
  },
  {
    number: "03",
    title: "Implementation",
    description: "Implementation is the crucible where theory meets reality. It's the stage where a well-crafted business model and R&D strategy are put to the test.",
  },
  {
    number: "04",
    title: "Monitoring and Optimization",
    description:
      "We maintain a vigilant watch on your business performance, employing key metrics to assess progress. By diligently analyzing feedback and market trends, we proactively implement necessary adjustments to optimize your business trajectory.",
  },
  {
    number: "05",
    title: "Reporting and Communication",
    description:
      "We maintain open and transparent communication, providing regular updates on your business's progress. Our commitment extends to fostering your growth by continuously sharing insights, expertise, and best practices.",
  },
  {
    number: "06",
    title: "Continual Improvement",
    description:
      "Continuous improvement is a systematic approach to identifying and implementing enhancements in products, processes, and services. It involves a relentless pursuit of perfection by making small, incremental changes over time. It's about creating a culture where everyone is involved in finding better ways to do things, leading to increased efficiency, quality, and customer satisfaction.",
  },
];

const WorkingProcess = () => {
  const [expandedStep, setExpandedStep] = useState(-1);

  return (
    <section className={styles.workingProcess}>
      <h2 className={styles.processTitle}>OUR WORKING PROCESS</h2>
      {processSteps.map((step, index) => (
        <ProcessStep
          key={index}
          number={step.number}
          title={step.title}
          description={step.description}
          isExpanded={index === expandedStep}
          onToggle={() => setExpandedStep(index === expandedStep ? -1 : index)}
        />
      ))}
    </section>
  );
};

export default WorkingProcess;
