import React from "react";
import Slider from "react-slick";
import styles from "./VentureBuilding.module.css";

import image1 from "../../utils/Assossiates/image1.png";
import image2 from "../../utils/Assossiates/image2.png";
import image3 from "../../utils/Assossiates/image3.png";
import image4 from "../../utils/Assossiates/image4.png";
import image5 from "../../utils/Assossiates/image5.png";
import image6 from "../../utils/Assossiates/image6.png";
import image7 from "../../utils/Assossiates/image7.png";
import image8 from "../../utils/Assossiates/image8.png";
import image9 from "../../utils/Assossiates/image9.png";
import image10 from "../../utils/Assossiates/image10.png";
import image11 from "../../utils/Assossiates/image11.png";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const partnerLogos = [
  { src: image1, alt: "Partner logo 1" },
  { src: image2, alt: "Partner logo 2" },
  { src: image3, alt: "Partner logo 3" },
  { src: image4, alt: "Partner logo 4" },
  { src: image5, alt: "Partner logo 5" },
  { src: image6, alt: "Partner logo 6" },
  { src: image7, alt: "Partner logo 7" },
  { src: image8, alt: "Partner logo 8" },
  { src: image9, alt: "Partner logo 9" },
  { src: image10, alt: "Partner logo 10" },
  { src: image11, alt: "Partner logo 11" },
];


const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      className={styles.nextArrow}
      onClick={onClick}
    />
  );
};

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      className={styles.prevArrow}
      onClick={onClick}
    />
  );
};


const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  autoplay: true,               // Enable autoplay
  autoplaySpeed: 2000,          // Set autoplay speed to 2 seconds
  nextArrow: <NextArrow />,     // Custom next arrow component
  prevArrow: <PrevArrow />,     // Custom previous arrow component
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 2,
      },
    },
  ],
};

const Partners = () => (
  <section className={styles.partnersSection}>
    <h2 className={styles.partnersTitle}>
      Meet the People <br />
      <strong>We are Working With</strong>
    </h2>
    <div className={styles.partnerSliderWrapper}>
      <Slider {...settings} className={styles.partnerSlider}>
        {partnerLogos.map((logo, index) => (
          <div key={index} className={styles.partnerLogoWrapper}>
            <img src={logo.src} alt={logo.alt} className={styles.partnerLogo} />
          </div>
        ))}
      </Slider>
    </div>
  </section>
);

export default Partners;
