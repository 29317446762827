/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styles from "./SectionTitle.module.css";

function SectionTitle({ children }) {
  return <h1 className={styles.sectionTitle}>{children}</h1>;
}

export default SectionTitle;
