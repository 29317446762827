import React from "react";
import styles from "./VentureBuilding.module.css";

function ValueCard({ icon, title, description }) {
  return (
    <div className={styles.valueCard}>
      <div className={styles.valueIconWrapper}>
        <img src={icon} alt="" className={styles.valueIcon} />
      </div>
      <h3 className={styles.valueTitle}>{title}</h3>
      <p className={styles.valueDescription}>{description}</p>
    </div>
  );
}

export default ValueCard;
