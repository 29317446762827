import React from "react";
import styles from "./ContactCard.module.css";

const ContactCard = ({ icon, title, content, type }) => {
  return (
    <div className={`${styles.contactCard} ${styles[type]}`}>
      <img src={icon} alt={`${title} icon`} className={styles.cardIcon} />
      <h4 className={styles.cardTitle}>{title}</h4>
      {Array.isArray(content) ? (
        content.map((item, index) => (
          <p key={index} className={styles.cardContent}>
            {item}
          </p>
        ))
      ) : (
        <p className={styles.cardContent}>{content}</p>
      )}
    </div>
  );
};

export default ContactCard;
