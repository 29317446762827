import React from "react";
import styles from "./VentureBuilding.module.css";
import { Link } from "react-router-dom";

function HeroSection() {
  return (
    <section className={styles.heroSection}>
      <div className={styles.heroContent}>
        <h1 className={styles.heroTitle}>
          <span className={styles.heroTitleLight}>Great</span>{" "}
          <span className={styles.heroTitleBoldWhite}>Product</span>{" "}
          <span className={styles.heroTitleLight}>is</span>
          <br />
          <span className={styles.heroTitleBoldWhite}>built by great</span> <span className={styles.heroTitleBold}>teams</span>
        </h1>
        <p className={styles.heroSubtitle}>
          We provide holistic support to all clients
        </p>
        <Link to="/Contact_Us" className={styles.ctaButton}>Let's get started!</Link>
      </div>
      <div className={styles.heroImageWrapper}>
        <img
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/6636076f290bc3941392e386519b1ca34b27bffff3b7888b256e34f5571302d9?apiKey=19cd871fe5a5421a8a01e183ca4b7671&&apiKey=19cd871fe5a5421a8a01e183ca4b7671"
          alt="Hero illustration"
          className={styles.heroImage}
        />
      </div>
    </section>
  );
}

export default HeroSection;
