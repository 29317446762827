import React from "react";
import styles from "./ContactImage.module.css";

function ContactImage() {
  return (
    <div className={styles.imageWrapper}>
      <img
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/b0a896beaf8620ccf1a9b64609991f460fea4845d0fa5103b2926cf877d07068?apiKey=19cd871fe5a5421a8a01e183ca4b7671&&apiKey=19cd871fe5a5421a8a01e183ca4b7671"
        alt="Contact illustration"
        className={styles.contactImage}
      />
    </div>
  );
}

export default ContactImage;
