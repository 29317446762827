/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styles from "./VentureBuilding.module.css";
import HeroSection from "./HeroSection";
import ServicesSection from "./ServicesSection";
import AboutUsSection from "./AboutUsSection";
import VisionMissionSection from "./VisionMissionSection";
import WhyChooseUsSection from "./WhyChooseUsSection";
import ProjectsSection from "./ProjectsSection";
import ProcessSection from "./ProcessSection";
import ValuesSection from "./ValuesSection";


function VentureBuilding() {
  return (
    <div className={styles.ventureBuilding}>      
      <main>
        <HeroSection />
        <ServicesSection />
        <AboutUsSection />
        <VisionMissionSection />
        <WhyChooseUsSection />
        <ProjectsSection />
        <ProcessSection />
        <ValuesSection />
      </main>
    </div>
  );
}

export default VentureBuilding;
