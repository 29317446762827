import React from "react";
import { Link } from "react-router-dom";
import styles from "./VentureBuilding.module.css";

import project_image1 from '../../utils/main/project_image1.png'
import project_image2 from '../../utils/main/project_image2.png'
import project_image3 from '../../utils/main/project_image3.png'
import project_image4 from '../../utils/main/project_image4.png'

function ProjectsSection() {
  return (
    <section className={styles.projectsSection}>
      <h2 className={styles.sectionTitle}>
        <span className={styles.lightText}>Our recent</span>
        <br />
        PROJECTS
      </h2>
      <div className={styles.imageRow}>
        <img
          src={project_image1}
          alt="Project 1"
          className={styles.projectImage}
        />
      </div>
      <div className={styles.imageRow}>
        <img
          src={project_image2}
          alt="Project 2"
          className={styles.projectImage}
        />
        <img
          src={project_image3}
          alt="Project 3"
          className={styles.projectImage}
        />
      </div>
      <div className={styles.imageRow}>
        <img
          src={project_image4}
          alt="Project 4"
          className={styles.projectImage}
        />
      </div>
      <Link to="/Projects" className={styles.readMoreButton}>
        Read More
        <img
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/2687f6218a61058980ec5ee71ba28e48a9be4b9376e8ad9c61a02f2d16d6c5d2?apiKey=19cd871fe5a5421a8a01e183ca4b7671&&apiKey=19cd871fe5a5421a8a01e183ca4b7671"
          alt=""
          className={styles.readMoreIcon}
        />
      </Link>
    </section>
  );
}

export default ProjectsSection;
