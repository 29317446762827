import React from "react";
import Slider from "react-slick";
import styles from "./AboutUs.module.css";
import TeamMember from "./TeamMember";
import Member from "./Member";
import ContactCard from "./ContactCard";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


import lead_image1 from "../../utils/AboutUs/leads/lead_image1.png";
import lead_image2 from "../../utils/AboutUs/leads/lead_image2.png";
import lead_image3 from "../../utils/AboutUs/leads/lead_image3.png";
import lead_image4 from "../../utils/AboutUs/leads/lead_image4.png";
import lead_image5 from "../../utils/AboutUs/leads/lead_image5.png";
import lead_image6 from "../../utils/AboutUs/leads/lead_image6.png";

import team_image1 from '../../utils/AboutUs/team/team_image1.png'
import team_image2 from '../../utils/AboutUs/team/team_image2.png'
import team_image3 from '../../utils/AboutUs/team/team_image3.png'
import team_image4 from '../../utils/AboutUs/team/team_image4.png'
import team_image5 from '../../utils/AboutUs/team/team_image5.png'
import team_image6 from '../../utils/AboutUs/team/team_image6.png'
import team_image7 from '../../utils/AboutUs/team/team_image7.png'
import team_image8 from '../../utils/AboutUs/team/team_image8.png'
import team_image9 from '../../utils/AboutUs/team/team_image9.png'



const AboutUs = () => {
  const leadMembers = [
    {
      name: "Wong Wei Jer",
      role: "CEO",
      image: lead_image1,
    },
    {
      name: "Will Tan",
      role: "Business strategist",
      image: lead_image2,
    },
    {
      name: "Modhamad Fahad",
      role: "Security engineer",
      image: lead_image3,
    },
    {
      name: "Muhammad Ali Iftikhar",
      role: "Developer / UX-UI designer",
      image: lead_image4,
    },
    {
      name: "Corina",
      role: "Life Coach",
      image: lead_image5,
    },
    {
      name: "George Patin",
      role: "Business Strategist",
      image: lead_image6,
    }
  ]

  const teamMembers = [
    {
      name: "Ben Yew",
      role: "Back-End Developer",
      image:
        team_image1,
    },
    {
      name: "Fong Jun De",
      role: "Back-End Developer",
      image:
        team_image2,
    },
    {
      name: "Gan Kah Hee",
      role: "Back-End Developer",
      image:
        team_image3,
    },
    {
      name: "Lee Jia Qian",
      role: "Back-End Developer",
      image:
        team_image4,
    },
    {
      name: "Faisal Saleem",
      role: "Back-End Developer",
      image:
        team_image5,
    },
    {
      name: "Pavan",
      role: "Back-End Developer",
      image:
        team_image6,
    },
    {
      name: "Kumar",
      role: "Back-End Developer",
      image:
        team_image7,
    },
    {
      name: "Nathan Ryan",
      role: "Back-End Developer",
      image:
        team_image8,
    },
    {
      name: "Shaik",
      role: "Back-End Developer",
      image:
        team_image9,
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,               // Enable autoplay
    autoplaySpeed: 2000,          // Set autoplay speed to 3 seconds
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className={styles.aboutUs}>
        <header className={styles.header}>
          <img
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/0498412e9c5f142603d6f52b3f4720acb9e892dacf7976e8cd7855afacdc8501?apiKey=19cd871fe5a5421a8a01e183ca4b7671&&apiKey=19cd871fe5a5421a8a01e183ca4b7671"
            alt="About Us header background"
            className={styles.headerBackground}
          />
          <h1 className={styles.headerTitle}>About Us</h1>
        </header>
        <main className={styles.mainContent}>
          <section className={styles.missionSection}>
            <img
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/09039b03f34cee211f484dedd69e645ff25c5079a956633319df35e6e22f4cbd?apiKey=19cd871fe5a5421a8a01e183ca4b7671&&apiKey=19cd871fe5a5421a8a01e183ca4b7671"
              alt="Company mission visual"
              className={styles.missionImage}
            />
            <p className={styles.missionText}>
              Dusky is a crucible of ambition where passionate individuals
              converge to ignite innovation. We are a collective of
              high-achievers, united by a relentless pursuit of excellence.
              Joining Dusky is more than just a career move; it's an invitation to
              be part of something extraordinary. We seek collaborators who are
              not merely satisfied with the status quo but driven to redefine
              industry standards.
              <br />
              <br />
              When you join Dusky, you're not just an employee; you're a catalyst
              for change. We seek visionary minds, those who dare to dream big and
              have the tenacity to turn dreams into reality.
            </p>
          </section>
          <section className={styles.teamSection}>
            <h2 className={styles.teamTitle}>MEET OUR TEAM</h2>
            <Slider {...settings} className={styles.teamCarousel}>
              {leadMembers.map((member, index) => (
                <div key={index}>
                  <TeamMember {...member} />
                </div>
              ))}
            </Slider>
          </section>
          <section className={styles.teamContainer}>
            {teamMembers.map((member, index) => (
              <div key={index}>
                <Member {...member} />
              </div>
            ))}
          </section>
        </main>
      </div>
      <section className={styles.contactSection}>
        <h2 className={styles.contactTitle}>Get in touch</h2>
        <h3 className={styles.contactSubtitle}>Contact</h3>
        <div className={styles.contactCards}>
          <ContactCard
            icon="https://cdn.builder.io/api/v1/image/assets/TEMP/d354804e40448c6d087bdd211075aa06559551e39fe78619f48e7e28339ebaf3?apiKey=19cd871fe5a5421a8a01e183ca4b7671&&apiKey=19cd871fe5a5421a8a01e183ca4b7671"
            title="Phone"
            content="(65) 86510121"
            type="phone"
          />
          <ContactCard
            icon="https://cdn.builder.io/api/v1/image/assets/TEMP/4c912a60cc6bedbc8b9306be530da502c378310f171fe6bdcc446c1482dce658?apiKey=19cd871fe5a5421a8a01e183ca4b7671&&apiKey=19cd871fe5a5421a8a01e183ca4b7671"
            title="Email"
            content={["duskytechco@gmail.com"]}
            type="email"
          />
        </div>
      </section>
    </>
  );
};

export default AboutUs;
