import React from "react";
import styles from "./Member.module.css";

const Member = ({ name, role, image }) => {
  return (
    <div className={styles.teamMember}>
      <img
        src={image}
        alt={`${name} - ${role}`}
        className={styles.memberImage}
      />
      <h3 className={styles.memberName}>{name}</h3>
      <p className={styles.memberRole}>{role}</p>
    </div>
  );
};

export default Member;
