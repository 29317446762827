import React from "react";
import styles from "./TeamMember.module.css";

const TeamMember = ({ name, role, image }) => {
  return (
    <div className={styles.teamMember}>
      <img
        src={image}
        alt={`${name} - ${role}`}
        className={styles.memberImage}
      />
      <div className={styles.memberInfo}>
        <h3 className={styles.memberName}>{name}</h3>
        <p className={styles.memberRole}>{role}</p>
        <div className={styles.socialIcons}>
          <img
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/c4ecf34b0d6109ad2b54dd01f3a048ccc03786c204aa1b37e318d86577b2f004?apiKey=19cd871fe5a5421a8a01e183ca4b7671&&apiKey=19cd871fe5a5421a8a01e183ca4b7671"
            alt="Social icon"
            className={styles.socialIcon}
          />
          <img
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/8e9c7aaad024d5d0548a750ed4f8b65e64b4aad7001c7ce49fd5af5042d0d1f7?apiKey=19cd871fe5a5421a8a01e183ca4b7671&&apiKey=19cd871fe5a5421a8a01e183ca4b7671"
            alt="Social icon"
            className={styles.socialIcon}
          />
          <img
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/1809162fa2e0058bd8d3f2d6c8632989bb5f520990ee04d18e4d19c19c58b1c9?apiKey=19cd871fe5a5421a8a01e183ca4b7671&&apiKey=19cd871fe5a5421a8a01e183ca4b7671"
            alt="Social icon"
            className={styles.socialIcon}
          />
        </div>
      </div>
      <button className={styles.viewProfileButton}>View Profile</button>
    </div>
  );
};

export default TeamMember;
