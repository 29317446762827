import React from 'react';
import styles from './CustomPartners.module.css';
import image1 from "../../utils/Assossiates/image1.png";
import image2 from "../../utils/Assossiates/image2.png";
import image3 from "../../utils/Assossiates/image3.png";
import image4 from "../../utils/Assossiates/image4.png";
import image5 from "../../utils/Assossiates/image5.png";
import image7 from "../../utils/Assossiates/image7.png";
import image8 from "../../utils/Assossiates/image8.png";
import image9 from "../../utils/Assossiates/image9.png";
import image10 from "../../utils/Assossiates/image10.png";
import image11 from "../../utils/Assossiates/image11.png";
import image12 from "../../utils/Assossiates/image12.jpg";

const universityLogos = [
    { src: image1, alt: "Universiti Malaya" },
    { src: image2, alt: "Universitas Indonesia" },
    { src: image3, alt: "Sunway University" },
    { src: image4, alt: "UCSI University" },
    { src: image5, alt: "KLH University" },
    { src: image8, alt: "IIT Palakkad" },
    { src: image7, alt: "APU University" },
];

const softwareDevelopmentLogos = [
    { src: image9, alt: "ScalaCode" },
    { src: image10, alt: "76" },
    { src: image11, alt: "BenoSupport" },
];

const marketingLogos = [
    { src: image12, alt: "Flykez" },
];

export default function CustomPartners() {
    const universityClass = universityLogos.length < 4 ? `${styles.logos} ${styles.centered}` : styles.logos;
    const softwareClass = softwareDevelopmentLogos.length < 4 ? `${styles.logos} ${styles.centered}` : styles.logos;
    const marketingClass = marketingLogos.length < 4 ? `${styles.logos} ${styles.centered}` : styles.logos;

    return (
        <div className={styles.partnersContainer}>
            <div className={styles.partnerSection}>
                <h3 className={styles.header}>Universities</h3>
                <div className={universityClass}>
                    {universityLogos.map((logo, index) => (
                        <img key={index} src={logo.src} alt={logo.alt} className={styles.partnerLogo}/>
                    ))}
                </div>
            </div>

            <div className={styles.partnerSection}>
                <h3 className={styles.header}>Software Developments</h3>
                <div className={softwareClass}>
                    {softwareDevelopmentLogos.map((logo, index) => (
                        <img key={index} src={logo.src} alt={logo.alt} className={styles.partnerLogo}/>
                    ))}
                </div>
            </div>

            <div className={styles.partnerSection}>
                <h3 className={styles.header}>Marketing</h3>
                <div className={marketingClass}>
                    <img src={marketingLogos[0].src} alt={marketingLogos[0].alt} className={styles.partnerLogo} />
                </div>
            </div>
        </div>
    );
}
