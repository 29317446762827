import React from "react";
import Slider from "react-slick";
import styles from "./VentureBuilding.module.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const membershipLogos = [
  {
    src: "https://cdn.builder.io/api/v1/image/assets/TEMP/697b3505a28f3ebc346b271fbcef2c34f5ca29a294e16b895c00a0cff87c8820?apiKey=19cd871fe5a5421a8a01e183ca4b7671&&apiKey=19cd871fe5a5421a8a01e183ca4b7671",
    alt: "Membership logo 1",
  },
  {
    src: "https://cdn.builder.io/api/v1/image/assets/TEMP/5f650181eede79ab492dc01e9a97c0864c095e7235d54b930f180b69234e9c9e?apiKey=19cd871fe5a5421a8a01e183ca4b7671&&apiKey=19cd871fe5a5421a8a01e183ca4b7671",
    alt: "Membership logo 2",
  },
  {
    src: "https://cdn.builder.io/api/v1/image/assets/TEMP/818ee10985236dcf58bb2512933734f1360e934ad56dd4a017d8f55e95c9a14d?apiKey=19cd871fe5a5421a8a01e183ca4b7671&&apiKey=19cd871fe5a5421a8a01e183ca4b7671",
    alt: "Membership logo 3",
  },
];


const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      className={styles.nextArrow}
      onClick={onClick}
    />
  );
};

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      className={styles.prevArrow}
      onClick={onClick}
    />
  );
};


const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 2,
      },
    },
  ],
};


const Memberships = () => (
  <section className={styles.partnersSection}>
    <h2 className={styles.partnersTitle}>
      Members of ....
    </h2>
    <div className={styles.partnerSliderWrapper}>
      <Slider {...settings} className={styles.partnerSlider}>
        {membershipLogos.map((logo, index) => (
          <div key={index} className={styles.partnerLogoWrapper}>
            <img src={logo.src} alt={logo.alt} className={styles.partnerLogo} />
          </div>
        ))}
      </Slider>
    </div>
  </section>
);

export default Memberships;
