import React, { useState, useRef } from "react";
import styles from "./ProcessSection.module.css";
import ProcessCard from "./ProcessCard";

import efforts_image1 from '../../utils/main/efforts_image1.png';
import efforts_image2 from '../../utils/main/efforts_image2.png';

const processes = [
  {
    number: "01",
    title: "Software Development",
    description:
      "Developing a SaaS startup can be a grueling process, with development often consuming precious time and resources. We offer a streamlined solution that empowers you to focus on core business strategies while we handle the technical complexities. Our holistic approach provides comprehensive support, from strategic planning to product launch, ongoing management and security implementations. Our goal is to be your trusted partner, aligning our success with yours and accelerating your path to market.",
  },
  {
    number: "02",
    title: "Human Resource",
    description:
      "Managing your team efficiently is crucial for the success of your startup. We help you with recruitment, training, and management processes to ensure you have the right people in the right roles, driving your venture forward.",
  },
  {
    number: "03",
    title: "Marketing & Designing",
    description:
      "Effective marketing and design are essential for attracting and retaining customers. We provide comprehensive services from branding and design to marketing strategy and execution, ensuring your message reaches the right audience.",
  },
  {
    number: "04",
    title: "Business Strategy",
    description:
      "A strong business strategy is the foundation of a successful startup. We assist you in developing, refining, and implementing strategies that align with your vision and market demands, positioning your business for long-term success.",
  },
  {
    number: "05",
    title: "Market Research",
    description:
      "Understanding your market is key to making informed business decisions. Our market research services provide valuable insights into customer needs, competitive landscape, and emerging trends, helping you stay ahead of the curve.",
  },
  {
    number: "06",
    title: "Funding",
    description:
      "Securing funding is often one of the biggest challenges for startups. We guide you through the funding process, from preparing pitch decks to connecting with potential investors, ensuring you have the resources needed to grow your business.",
  },
];

function ProcessSection() {
  const [expandedIndex, setExpandedIndex] = useState(null);
  const solutionsRef = useRef(null);

  const handleToggle = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <section id="solutions-section" ref={solutionsRef} className={styles.processSection}>
      <h2 className={styles.mainSectionTitle}>Our Main Efforts</h2>

      <div className={styles.contentContainer}>
      <h3 className={styles.innerSectionTitle}>Venture Building</h3>
        <div className={styles.row}>
          <div className={styles.ventureBuilding}>
            <p>Venture Building Studio, Offering Holistic support</p>
            <ul>
              <li>Software Development</li>
              <li>Human Resource</li>
              <li>Marketing & Designing</li>
              <li>Business Strategy</li>
              <li>Market Research</li>
              <li>Funding</li>
            </ul>
          </div>
          <div className={styles.imageCard}>
            <img src={efforts_image1} alt="Venture Builder"/>
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.imageCard}>
            <img src={efforts_image2} alt="Team Members"/>
          </div>
          <div className={styles.textCard}>
            <h4>Build the right team to scale</h4>
            <p>Finding the right talent is not easy. We help you find the talent that suits your needs, follows your processes, and sticks with you long term (not the case with freelancers).</p>
            <p>Our delivery model helps you cut costs and deliver within budget.</p>
          </div>
        </div>
      </div>
      <div className={styles.processCards}>
        {processes.map((process, index) => (
          <ProcessCard
            key={index}
            {...process}
            isExpanded={expandedIndex === index}
            onToggle={() => handleToggle(index)}
          />
        ))}
      </div>
    </section>
  );
}

export default ProcessSection;
