import React, { useState } from "react";
import styles from "./Navbar.module.css";
import { Link, useNavigate } from "react-router-dom";
import dusky from '../../dusky.png'

const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleSolutionsClick = () => {
    navigate("/"); // Navigate to the VentureBuilding page
    setTimeout(() => {
      const element = document.getElementById("solutions-section");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  };

  return (
    <nav className={styles.navbar}>
      <img
        src={dusky}
        alt="Brand logo"
        className={styles.brandLogo}
      />
      <ul
        className={`${styles.navItems} ${isMobileMenuOpen ? styles.navItemsMobile : ""}`}
      >
        <li className={styles.navItem}>
          <Link to="/">Home</Link>
        </li>
        <li className={styles.navItem}>
          <a onClick={handleSolutionsClick}>Solutions</a>
        </li>
        <li className={styles.navItem}>
          <Link to="/Projects">Projects</Link>
        </li>
        <li className={styles.navItem}>
          <Link to="/Associates">Associates</Link>
        </li>
        <li className={styles.navItem}>
          <Link to="/About_Us">About Us</Link>
        </li>
      </ul>
      <button className={styles.contactButton}>
        <Link to="/Contact_Us">Contact us</Link>
      </button>
      <button className={styles.menuButton} onClick={toggleMobileMenu}>
        {isMobileMenuOpen ? 'X' : "☰"}
      </button>
    </nav>
  );
};

export default Navbar;
