import React from "react";
import styles from "./VentureBuilding.module.css";

function VisionMissionSection() {
  return (
    <section className={styles.visionMissionSection}>
      <div className={styles.visionSection}>
        <h2 className={styles.sectionTitleVisionMission}>Vision</h2>
        <p className={styles.sectionDescription}>
          Every company will be a unicorn
        </p>
      </div>
      <div className={styles.missionSection}>
        <h2 className={styles.sectionTitleVisionMission}>Mission</h2>
        <p className={styles.sectionDescription}>
          Making you achieve your goal at the same time we achieve ours
        </p>
      </div>
    </section>
  );
}

export default VisionMissionSection;
