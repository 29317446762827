import React from "react";
import styles from "./VentureBuilding.module.css";

const ProcessStep = ({ number, title, description, isExpanded, onToggle }) => (
  <div className={`${styles.processStep} ${isExpanded ? styles.expanded : ""}`}>
    <div className={styles.stepHeader} onClick={onToggle}>
      <div className={styles.stepNumber}>{number}</div>
      <h3 className={styles.stepTitle}>{title}</h3>
      <button
        className={styles.expandButton}
        aria-label={isExpanded ? "Collapse" : "Expand"}
      >
        {isExpanded ? "-" : "+"}
      </button>
    </div>
    {isExpanded && <p className={styles.stepDescription}>{description}</p>}
  </div>
);

export default ProcessStep;
