import React, { useState } from "react";
import Swal from 'sweetalert2';
import styles from "./ContactForm.module.css";

function ContactForm() {
  const [startTime, setStartTime] = useState(Date.now());

  const onSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    // Bot detection: Honeypot field
    if (formData.get("bot_field")) {
      Swal.fire({
        title: "Error!",
        text: "Bot detected. Submission blocked.",
        icon: "error"
      });
      return;
    }

    // Bot detection: Check form submission time
    const endTime = Date.now();
    if ((endTime - startTime) < 5000) { // Less than 5 seconds
      Swal.fire({
        title: "Error!",
        text: "Form submitted too quickly. Are you a bot?",
        icon: "error"
      });
      return;
    }

    // Add your access key here
    formData.append("access_key", "b459d986-52f6-43d7-8ae5-b77e75ab5741");

    // Convert FormData to a JSON object
    const object = Object.fromEntries(formData);
    const json = JSON.stringify(object);

    console.log(json);

    // Send the form data to Web3Forms
    const res = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      },
      body: json
    }).then((res) => res.json());

    // Handle the response
    if (res.success) {
      Swal.fire({
        title: "Success!",
        text: "Message sent successfully!",
        icon: "success"
      });
      event.target.reset();
    } else {
      Swal.fire({
        title: "Error!",
        text: "Something went wrong. Please try again.",
        icon: "error"
      });
    }
  };

  return (
    <form className={styles.contactForm} onSubmit={onSubmit}>
      {/* Honeypot field */}
      <input
        type="text"
        name="bot_field"
        style={{ display: "none" }}
        tabIndex="-1"
        autoComplete="off"
      />

      <div className={styles.formGroup}>
        <label htmlFor="name" className={styles.formLabel}>
          Name
        </label>
        <input type="text" id="name" name="name" className={styles.formInput} required />
      </div>
      <div className={styles.formGroup}>
        <label htmlFor="email" className={styles.formLabel}>
          Email Address
        </label>
        <input type="email" id="email" name="email" className={styles.formInput} required />
      </div>
      <div className={styles.formGroup}>
        <label htmlFor="message" className={styles.formLabel}>
          Type your message here
        </label>
        <textarea
          id="message"
          name="message"
          className={styles.formTextarea}
          required
        ></textarea>
      </div>
      <button type="submit" className={styles.submitButton}>
        Submit
      </button>
    </form>
  );
}

export default ContactForm;
