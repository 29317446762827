import VentureBuilding from '../components/Home_Page/VentureBuilding'
import LandingPage from '../components/Associates/LandingPage'
import AboutUs from '../components/About_Us/AboutUs'
import ContactPage from '../components/Contact_Us/ContactPage'
import ProjectSection from '../components/Projects/ProjectSection'

const routes = [
    {
        path: '/',
        element: <VentureBuilding />
    },
    {
        path: '/Associates',
        element: <LandingPage />
    },
    {
        path: '/About_Us',
        element: <AboutUs />

    },
    {
        path: '/Contact_Us',
        element: <ContactPage />
    },
    {
        path: '/Projects',
        element: <ProjectSection />
    },
    {
        path: '*',
        element: <VentureBuilding />
    }
]


export default routes;