import React from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "./Footer.module.css";
import dusky from '../../dusky.png'

const Footer = () => {

  const navigate = useNavigate();

  const handleSolutionsClick = () => {
    navigate("/"); // Navigate to the VentureBuilding page
    setTimeout(() => {
      const element = document.getElementById("solutions-section");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  };

  return (
    <footer className={styles.footer}>
      <div className={styles.footerContent}>
        <div className={styles.footerSection}>
          <img
            src={dusky}
            alt="Company logo"
            className={styles.footerLogo}
          />
          <p className={styles.footerText}>
            We are here to see you succeed this is not just for ourselves
          </p>
        </div>
        <div className={styles.footerSection}>
          <h4 className={styles.footerTitle}>Important Links</h4>
          <ul className={styles.footerLinks}>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <a onClick={handleSolutionsClick}>Solutions</a>
            </li>
            <li>
              <Link to="/Associates">Associates</Link>
            </li>
            <li>
              <Link to="/About_Us">About Us</Link>
            </li>
          </ul>
        </div>
        <div className={styles.footerSection}>
          <h4 className={styles.footerTitle}>Contact Us</h4>
          <p>Call: (65) 86510121</p>
          <p>Email: duskytechco@gmail.com</p>
        </div>
        <div className={styles.footerSection}>
          <h4 className={styles.footerTitle}>Newsletter</h4>
          <form className={styles.newsletterForm}>
            <label htmlFor="emailInput" className={styles.newsletterLabel}>
              Enter your email address
            </label>
            <div className={styles.newsletterInputGroup}>
              <input
                type="email"
                id="emailInput"
                placeholder="Enter your email address"
                className={styles.newsletterInput}
                aria-label="Enter your email address"
              />
              <button type="submit" className={styles.newsletterButton}>
                <img
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/83032258e5725411ddb5d208ac5f3f2f74e000f8851d3c2139aee44f1beec53a?apiKey=19cd871fe5a5421a8a01e183ca4b7671&&apiKey=19cd871fe5a5421a8a01e183ca4b7671"
                  alt="Submit newsletter"
                />
              </button>
            </div>
          </form>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
