import React from "react";
import styles from "./VentureBuilding.module.css";

const meetingLink = "https://calendar.app.google/QjFcYjoyztyQxHgVA"

const Hero = () => (

  <section className={styles.heroSection}>
    <div className={styles.heroContent}>
      <h1 className={styles.heroTitle}>Navigating the landscape for success</h1>
      <p className={styles.heroDescription}>
        Our Services make sure your business grows without an issue.
      </p>
      <button className={styles.ctaButton}>
        <a
          href={meetingLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          Schedule a meeting
        </a>
      </button>

    </div>
    <img
      src="https://cdn.builder.io/api/v1/image/assets/TEMP/ab639bacb4112cdec4209d54963cbe61209a302d977ffcbb9087a2e685e5f630?apiKey=19cd871fe5a5421a8a01e183ca4b7671&&apiKey=19cd871fe5a5421a8a01e183ca4b7671"
      alt="Business growth illustration"
      className={styles.heroImage}
    />
  </section>
);

export default Hero;
