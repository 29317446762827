import React from "react";
import styles from "./VentureBuilding.module.css";

function WhyChooseUsSection() {
  return (
    <section className={styles.whyChooseUsSection}>
      <h2 className={styles.sectionTitleWhyChooseUs}>Why Choose us?</h2>
      <div className={styles.whyChooseUsContent}>
        <img
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/17f46c87fdc7b5083358fc847b68dddfedd21fd116764d42d2ec01684e1c2c67?apiKey=19cd871fe5a5421a8a01e183ca4b7671&&apiKey=19cd871fe5a5421a8a01e183ca4b7671"
          alt=""
          className={styles.whyChooseUsIcon}
        />
        <p className={styles.whyChooseUsDescription}>
          We prioritize your profit. We invest in your success before our own.
          Our comprehensive approach encompasses planning, building, and
          managing your business to maximize your runway and minimize risk.
          <br />
          <br />
          Operating at our <strong>cost level</strong>, we ensure you retain
          maximum value while we refine your business model for exceptional
          growth. Our in-house expertise and resources reduce reliance on
          third-party vendors.
        </p>
      </div>
    </section>
  );
}

export default WhyChooseUsSection;
