import React from "react";
import styles from "./VentureBuilding.module.css";

function ProcessCard({ number, title, description, isExpanded, onToggle }) {
  return (
    <div
      className={`${styles.processCard} ${isExpanded ? styles.expanded : ""}`}
    >
      <div className={styles.processHeader}>
        <span className={styles.processNumber}>{number}</span>
        <h3 className={styles.processTitle}>{title}</h3>
        <button
          className={styles.expandButton}
          aria-label={isExpanded ? "Collapse" : "Expand"}
          onClick={onToggle}
        >
          {isExpanded ? "-" : "+"}
        </button>
      </div>
      {isExpanded && description && (
        <p className={styles.processDescription}>{description}</p>
      )}
    </div>
  );
}

export default ProcessCard;
